import Seo from "../components/layout/seo";
import Contact from "../components/pages/contact";
import React from "react";

export default ({ location }) => (
  <>
    <Seo
      title="Conttatti"
      desc="Contattaci per telefono o su WhatsApp, oppure lasciaci un messaggio attraverso il form su questa pagina e ti ricontatteremo il prima possibile!"
    />
    <Contact location={location} />
  </>
);
